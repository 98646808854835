import { Link } from 'gatsby';
import styled from 'styled-components';

export const Containerbreadcrumbs = styled.div`
  background-color: white;
  padding: 7px 10px;
  display: flex;
  gap: 5px;
  font-size: 0.9rem;
  width: 100%;
`;

export const Breadcrumb = styled.div`
  display: flex;
  gap: 5px;
`;

export const ItemLink = styled(Link)`
  text-decoration: none;
  color: #3DA5F2;
`;
