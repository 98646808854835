import React from 'react';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import {
  Container, Details, Image, Title,
} from './hero.elements';

const Hero = ({
  image, title, publishDate, timeToRead,
}: Dto) => (
  <Container>
    <Title>{title}</Title>
    <Details>
      <>
        <div>Por Electriredes Lussan</div>
        <div>{publishDate.charAt(0).toUpperCase() + publishDate.slice(1)}</div>
        <div>{timeToRead} minutos de lectura</div>
      </>
    </Details>
    {image && (
      <Image alt={title} image={image} objectFit="contain" />
    )}
  </Container>
);

export default Hero;

interface Dto {
  title: string,
  image: IGatsbyImageData,
  publishDate: string,
  timeToRead: number
}
