import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Breadcrumb, Containerbreadcrumbs, ItemLink,
} from './bread-crumb.elements';

const BreadCrumb = () => {
  const [location, setLocation] = useState(['']);
  useEffect(() => {
    const breadCumbs = window.location.pathname.split('/').filter((x) => x !== '');
    setLocation(breadCumbs);
  }, []);

  return (
    <Containerbreadcrumbs>
      <ItemLink to="/">Inicio</ItemLink>
      {
        location.map((breadCumb, i) => breadCumb && (
          <Breadcrumb key={breadCumb}>
            <p>{'>>'}</p>
            {i < location.length - 1 ? <ItemLink to={`/${breadCumb}`}>{breadCumb.replaceAll('-', ' ')}</ItemLink>
              : <strong>{breadCumb.replaceAll('-', ' ')}</strong>}
          </Breadcrumb>
        ))
      }
    </Containerbreadcrumbs>
  );
};

export default BreadCrumb;
