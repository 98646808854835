/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import readingTime from 'reading-time';

import Seo from '../components/seo';
import Layout from '../components/layout';
import Hero from '../components/hero/hero';
import {
  Container, Wrapper, Image, ContainerRichText, Tags, Sticky, ItemLink, Related,
} from './blog.elements';
import BreadCrumb from '../components/bread-crumb/bread-crumb';

const BlogPostTemplate = (props: React.ReactNode) => {
  const post = get(props, 'data.contentfulBlog');
  const previous = get(props, 'data.previous');
  const next = get(props, 'data.next');
  const next2 = get(props, 'data.next2');
  const recent = get(props, 'data.recent').nodes;
  const companyInfo = get(props, 'data.allContentfulMainPage').nodes[0];

  const plainTextBody = documentToPlainTextString(JSON.parse(post.body.raw));
  const { minutes: timeToRead } = readingTime(plainTextBody);

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
        const { gatsbyImage, description, title } = node.data.target;
        const position = title.match(/\b(left|right)\b/)?.[0];

        return (
          <Image
            position={position}
            objectFit="contain"
            image={getImage(gatsbyImage) as IGatsbyImageData}
            alt={description}
          />
        );
      },
    },
  };

  return (
    <Layout>
      <main>
        <Wrapper>
          <Container>
            <Hero
              image={post.image?.gatsbyImage}
              title={post.title}
              publishDate={post.publishDate}
              timeToRead={Math.round(timeToRead * 100) / 100}
            />
            <BreadCrumb />
            <ContainerRichText>
              {post.body?.raw && renderRichText(post.body, options)}
            </ContainerRichText>

            <Tags>
              <>
                <p>Tags:</p>
                {
                  post.tags && post.tags.map((tag: string) => (<div key={tag}>{tag}</div>))
                }
              </>
            </Tags>

            <Related>
              <p>Relacionados</p>
              {(previous || next || next2) && (
                <>
                  {previous && (
                    <div>
                      <ItemLink to={`/blog/${previous.slug}`} rel="prev">
                        {previous.title}
                      </ItemLink>
                      <p>{previous.publishDate}</p>
                    </div>
                  )}
                  {next && (
                    <div>
                      <ItemLink to={`/blog/${next.slug}`} rel="next">
                        {next.title}
                      </ItemLink>
                      <p>{next.publishDate}</p>
                    </div>
                  )}
                  {next2 && (
                    <div>
                      <ItemLink to={`/blog/${next2.slug}`} rel="next">
                        {next2.title}
                      </ItemLink>
                      <p>{next2.publishDate}</p>
                    </div>
                  )}
                </>
              )}
            </Related>

          </Container>
          <Container>
            <Sticky>
              <p>{companyInfo.companyName}</p>
              <GatsbyImage
                image={getImage(companyInfo.logo) as IGatsbyImageData}
                alt="logo"
              />
              <p>Entradas Recientes</p>
              {recent && (
                recent.map((e: { slug: string, title: string }) => (<ItemLink key={e.slug} to={`/blog/${e.slug}`}>{e.title}</ItemLink>))
              )}
              <p>Frases</p>
              <p> «El tamaño de tu éxito se mide por la fortaleza de tu deseo, el tamaño de tus sueños y como manejas las decepciones por el camino.» <br />Robert Kiyosaki</p>
              <p>«No ahorres lo que te queda despues de gastar, gasta lo que te queda despues de ahorrar»<br />Warren Buffett</p>
              <p>«El triunfo del verdadero hombre surge de las cenizas del error» <br />Pablo Neruda</p>
            </Sticky>
          </Container>
        </Wrapper>
      </main>
    </Layout>
  );
};

export default BlogPostTemplate;

export const Head = ({ pageContext }: any) => (
  <Seo
    title={pageContext.title}
    description={pageContext.title}
  />
);

export const pageQuery = graphql`
  query BlogPostBySlug(
    $slug: String!
    $previousPostSlug: String
    $nextPostSlug: String
    $nextPostSlug2: String
    $recentPosts: [String]
  ) {
    contentfulBlog(slug: { eq: $slug }) {
      slug
      title
      publishDate(formatString: "MMMM DD, YYYY", locale: "es")
      image {
        gatsbyImage(layout: FULL_WIDTH, placeholder: BLURRED, width: 1300)
        resize(width: 1300) {
          src
        }
      }
      body {
        raw
        references {
          contentful_id
          __typename
          description
          gatsbyImage(width: 1000)
          title
        }
      }
      tags
    }
    previous: contentfulBlog(slug: { eq: $previousPostSlug }) {
      slug
      title
      publishDate(formatString: "MMMM DD, YYYY", locale: "es")
    }
    next: contentfulBlog(slug: { eq: $nextPostSlug }) {
      slug
      title
      publishDate(formatString: "MMMM DD, YYYY", locale: "es")
    }
    next2: contentfulBlog(slug: { eq: $nextPostSlug2 }) {
      slug
      title
      publishDate(formatString: "MMMM DD, YYYY", locale: "es")
    }
    recent: allContentfulBlog(filter: { slug: { in: $recentPosts } }) {
      nodes {
        slug
        title
      }
    }
    allContentfulMainPage {
      nodes {
        companyName
        logo {
          gatsbyImageData(width: 250)
        }
      }
    }
  }
  `;
