import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 200px;
  position: relative;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    padding-top: var(--size-gutter);
  }  
`;

export const Image = styled(GatsbyImage)`
  height: auto;
  width: 100%;
`;

export const Title = styled.h1`
  letter-spacing: -0.012em;
  font-weight: var(--extrabold);
  line-height: var(--solid);
  width: 100%;
  text-align: left;
`;

export const Details = styled.div`
  display: flex;
  margin-bottom: 10px;
  font-size: 0.65rem;

  @media screen and (min-width: 788px) {
    font-size: 0.85rem;
  }

  div:nth-child(1) {
    padding-right: 7px;
    border-right: 2px solid black;
  }

  div:nth-child(2) {
    padding-right: 7px;
    padding-left: 7px;
    border-right: 2px solid black;
  }

  div:nth-child(3) {
    padding-left: 7px;
  }
`;
