import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 2rem 3vw;
  justify-content: center;
  width: 100%;
  margin: auto;
  background-color: #E9ECEF;

  @media screen and (min-width: 788px) {
    padding: 2rem 11vw;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 950px;
  text-align: justify;
  align-content: flex-start;
  
  &:nth-child(1) {
    width: 100%;

    @media screen and (min-width: 788px) {
      width: 75%;
      border-right: 1px solid gray;
      padding-right: 40px;
    }
  }

  &:nth-child(2) {
    display: none;
    width: 25%;
    padding-left: 20px;
    position: relative;

    @media screen and (min-width: 788px) {
      display: block;
    }
  }

`;

export const ContainerRichText = styled.div`
  display: block;
  width: 100%;
  text-align: justify;
  margin-bottom: 20px;

  & ul {
    margin-left: 20px;
    margin-bottom: 15px;
    margin-top: 15px;
  }

  &:not(li) p {
    padding: 7px 0px;
    text-align: justify;
    line-height: 1.7em;
    font-size: 0.95rem;
  }

  & li p {
    padding: 0px;
  }

  & h2,h3 {
    margin: 15px 0px;
    width: 100%;
    overflow: hidden;
  }

  & hr {
    overflow: hidden;
    width: 100%;
  }
`;

interface ImageProps {
  position?: string
}
export const Image = styled(GatsbyImage)<ImageProps>`
  height: auto;
  width: ${({ position }) => (position ? '50%' : '100%')};
  float: ${({ position }) => (position ?? 'none')};
  margin: ${({ position }) => (position === 'left' ? '7px 7px 7px 0px' : '7px 0px 7px 7px')};

  @media screen and (min-width: 788px) {
    margin: ${({ position }) => (position === 'left' ? '15px 15px 15px 0px' : '15px 0px 15px 15px')};
    padding-right: 50px;
  }
`;

export const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  font-size: 0.9rem;
  justify-content: center;

  & p {
    font-weight: bold;
    font-size: 1rem;
  }

  div:not(:last-child) {
    border-right: 2px solid black;
    padding-right: 10px;
  }
`;

export const Sticky = styled.div`
  position: sticky;
  top: 20px;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  font-size: 0.9rem;

  p {
    margin-bottom: 20px;
  }

  p:first-child {
    font-weight: 600;
    margin-bottom: 10px;
  }

  p:nth-child(3),
  p:nth-child(6) {
    width: 100%;
    font-weight: 500;
    font-size: 1.1rem;
    text-underline-offset: 5px;
    text-decoration: underline;
  }

  .gatsby-image-wrapper {
    margin: 20px 0px;
  }
`;

export const ItemLink = styled(Link)`
  text-decoration: none;
  color: black;
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  &:hover {
    color: #59c2e6;
  }
`;

export const Related = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 10%;
  width: 100%;
  margin-top: 15px;

  p:first-child {
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 1rem;
    border-bottom: 1px solid black;
  }

  & div {
    width: 45%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 25px;

    @media screen and (min-width: 788px) {
      width: 25%;
    }
  }

  & p {
    width: 100%;
    font-size: 0.8rem;
  }

  ${ItemLink} {
    color: #0e81ec;
    margin-bottom: 0px;

    &:hover {
      color: #59c2e6;
    }
  }
`;
